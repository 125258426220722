<template>
  <SumHeaderTable
    :value="keywords"
    :global-filter-fields="['keywordText']"
    :columns="columns"
    :sums="sums"
    table-prefix="general"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import SumHeaderTable from '../../../../SumHeaderTable.vue';
import generateCommonColumns from '../../../../../utils/tableUtils';
import { formatBid, formatMatchType } from '../../../../../utils/formatUtils';

export default {
  components: { SumHeaderTable },
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    selectedProfile: {
      type: Object,
      required: true,
    },
    keywords: {
      type: Array,
      required: true,
    },
    sums: {
      type: Object,
      required: true,
    },
  },
  data() {
    const columns = [
      {
        id: 'keywordText',
        field: 'keywordText',
        header: 'Keyword',
        sortable: true,
        numeric: false,
        decimal: false,
        visible: true,
        alwaysVisible: true,
        notInFilter: true,
        width: '300px',
        content: {
          center: false,
          to: null,
          badge: null,
          format: null,
        },
      },
      {
        id: 'keywordState',
        field: 'state',
        header: 'Status',
        sortable: true,
        numeric: false,
        decimal: false,
        visible: true,
        alwaysVisible: true,
        notInFilter: true,
        width: '100px',
        content: {
          center: false,
          to: null,
          badge: true,
          format: null,
        },
      },
      {
        id: 'keywordMatchType',
        field: 'matchType',
        header: 'Match Type',
        sortable: true,
        numeric: false,
        decimal: false,
        visible: true,
        alwaysVisible: true,
        notInFilter: true,
        width: '120px',
        content: {
          center: true,
          to: null,
          badge: null,
          format: (data) => formatMatchType(data),
        },
      },
      {
        id: 'keywordBid',
        field: 'bid',
        header: 'Bid',
        sortable: true,
        numeric: false,
        decimal: true,
        visible: true,
        alwaysVisible: true,
        notInFilter: true,
        width: '80px',
        content: {
          center: true,
          to: null,
          remove: '$',
          badge: null,
          format: (data) => formatBid(data, this.$store.state.auth.selectedProfile.currencyCode),
        },
      },
      ...generateCommonColumns(() => this.$store.state.auth.selectedProfile),
    ].map((column, index) => ({ ...column, order: index }));

    return {
      columns,
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },
};
</script>

<style scoped lang="scss">
@import "../../../../../assets/styles/dashboard/campaigns.scss";
</style>
