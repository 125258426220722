import { ReportStatus } from '../../constants/reportConstants';
import { accumulateGroupedKpi, tryParseContent } from './reportUtils';

const buildReportContentByAdGroup = (reportsResponse) => {
  const reportsContent = [];

  reportsResponse.filter((report) => report.state === ReportStatus.COMPLETE).forEach((report) => {
    tryParseContent(report.content).forEach((entry) => reportsContent.push(entry));
  });
  return reportsContent;
};

const groupReportDataByQuery = (reportsContent) => {
  const groupedKeywords = reportsContent.reduce((acc, obj) => {
    const key = obj.query;
    return accumulateGroupedKpi(acc, obj, key);
  }, {});
  return groupedKeywords;
};

const aggregateByKeyword = (reportsContent, keywords) => {
  const aggregatedKeywords = reportsContent.reduce((acc, obj) => {
    const key = obj.keywordId;
    const keyword = keywords.find((k) => k.keywordId === obj.keywordId);
    return accumulateGroupedKpi(acc, obj, key, keyword);
  }, {});
  return aggregatedKeywords;
};

const mergeApiKeywords = (keywordData, apiKeywords) => {
  const mergedApiKeywords = [];
  apiKeywords.forEach((apiKeyword) => {
    const currentKeywordData = keywordData.find((data) => data.keywordId === apiKeyword.keywordId);
    if (currentKeywordData) {
      mergedApiKeywords.push({ ...currentKeywordData, ...apiKeyword });
    } else if (apiKeyword.state === 'ENABLED') {
      mergedApiKeywords.push({
        clicks: 0,
        cost: 0,
        impressions: 0,
        sales14d: 0,
        purchases14d: 0,
        kindleEditionNormalizedPagesRoyalties14d: 0,
        keywordType: apiKeyword.matchType,
        ...apiKeyword,
      });
    }
    // Don't add keywords that are not in the reports and are not enabled
  });
  return mergedApiKeywords;
};

export {
  buildReportContentByAdGroup, aggregateByKeyword, groupReportDataByQuery, mergeApiKeywords,
};
