<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <template v-else>
    <template v-if="campaign.targetingType === 'MANUAL'">
      <TargetsTable
        v-if="targets.length > 0 "
        :targets="targets"
        :sums="targetSums"
      />
      <KeywordsTable
        v-else
        :selected-profile="selectedProfile"
        :keywords="keywords"
        :sums="keywordSums"
      />
    </template>
    <template v-else>
      <TargetsTable
        :auto-targets="true"
        :targets="targets"
        :sums="targetSums"
      />
    </template>
  </template>
</template>
<script>
import dayjs from 'dayjs';
import { ReportType, REPORT_DATE_FORMAT } from '../../../../../constants/reportConstants';

import A2CAClient from '../../../../../api/a2caAuthorizedClient';
import KeywordsTable from './KeywordsTable.vue';
import TargetsTable from './TargetsTable.vue';
import { mergeApiKeywords } from '../../../../../utils/reports/keywordReportUtils';
import { mergeApiTargets } from '../../../../../utils/reports/targetReportUtils';

export default {
  components: { KeywordsTable, TargetsTable },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      required: true,
    },
    adGroup: {
      type: Object,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      keywords: null,
      targets: null,
      keywordSums: null,
      targetSums: null,
    };
  },
  computed: {
    campaignId() {
      return this.campaign.campaignId;
    },
    adGroupId() {
      return this.adGroup.adGroupId;
    },
  },
  watch: {
    dateRange(range) {
      if (range[1] !== null) {
        this.fetchTargeting();
      }
    },
    selectedProfile: {
      handler() {
        this.fetchTargeting();
      },
      immediate: true,
    },
  },
  methods: {
    requestTargets() {
      const client = new A2CAClient(this.query);

      const targetReportsRequest = client.getReports(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.TARGETS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
        this.adGroupId,
        ReportType.AD_GROUPS,
        this.campaignId,
      );

      const targetsRequest = client.getTargets(this.selectedProfile.profileId,
        this.selectedProfile.region, this.adGroupId);

      return [targetReportsRequest, targetsRequest];
    },
    parseTargets(targetReportsResponse, targets) {
      const { reportData } = targetReportsResponse.parsedReport;
      this.targetSums = targetReportsResponse.aggregation;

      this.targets = mergeApiTargets(reportData, targets);
    },
    requestKeywords() {
      const client = new A2CAClient(this.query);
      const keywordReportsRequest = client.getReports(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.KEYWORDS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
        this.adGroupId,
        ReportType.AD_GROUPS,
        this.campaignId,
      );

      const keywordsRequest = client.getKeywords(this.selectedProfile.profileId,
        this.selectedProfile.region, this.adGroupId);

      return [keywordReportsRequest, keywordsRequest];
    },
    parseKeywords(keywordsReportResponse, keywords) {
      const { reportData } = keywordsReportResponse.parsedReport;
      this.keywordSums = keywordsReportResponse.aggregation;

      this.keywords = mergeApiKeywords(reportData, keywords);
    },
    async fetchTargeting() {
      this.loading = true;
      const requests = [...this.requestTargets(), ...this.requestKeywords()];
      const [targetsResponse, targets, keywordsReportResponse, keywords] = await Promise.all(requests);

      // Set adgroup's default bid to targets that have no bid
      const biddedTargets = targets.map((target) => ({ ...target, bid: target.bid === null ? this.adGroup.defaultBid : target.bid }));

      this.parseTargets(targetsResponse, biddedTargets);
      const biddedKeywords = keywords.map((keyword) => ({ ...keyword, bid: keyword.bid === null ? this.adGroup.defaultBid : keyword.bid }));
      this.parseKeywords(keywordsReportResponse, biddedKeywords);

      this.loading = false;
    },
  },
};
</script>
